/* eslint-disable no-invalid-this */
/* eslint-disable max-statements */
/* eslint-disable no-magic-numbers */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";

// Icons
import ArrowIcon from "../../shared/arrow";
// import IconMenu from "./images/menu.svg";
import IconLocationOrange from "./images/location-on-orange.svg";

// Tracking
import { trackDesktopCustomEventsAE, trackDesktopMultipleEventsAE } from "../../../tracking/desktop/tracking";

// Components
import Logo from "../../shared/logo/component";
import withLogin from "../with-login";
import UsedCarDropdown from "./../used-car-dropdown";
import MenuSideBar from "./../menu-side-bar";

// Styles
import styles from "./styles.css";
import homeObject from "../../../locales/desktop/home";

// Constants
import { appUrl } from "../../../constants/url-constants";
import { SELL_CAR_HEADER_INFO, GA_EVENT_DATA } from "./constant";
import { BOOKING_CONFIRMATION_SOURCE } from "../../../constants/app-constants";
import { ROUTE_NAME, ROUTE_PATHS } from "../../../constants/routes-constants";
import MakeModalSearchInfo from "../make-modal-search-info";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import useOutsideClick from "../../../hooks/use-outside-click";
import useLink from "../../../hooks/use-link";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";

// eslint-disable-next-line complexity
const HeaderRevampV2 = ({
    selectedCity: {
        name: selectedCityName
    },
    route: {
        name: routeName
    },
    allFilters,
    updateLocationPopupShownConnect,
    isLoggedIn,
    cities,
    reloadCarListConnect
}) => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = parseLocationSearchParams(location.search);
    const [searchTerm, setSearchTerm] = useState(queryParams.search);
    const [isSearchModal, setIsSearchModal] = useState(queryParams.source === "searchModel" ? true : false);
    const isHomePage = routeName === ROUTE_NAME.HOME;
    const isProfilePage = routeName === ROUTE_NAME.PROFILE;
    const isCheckoutPage = routeName === ROUTE_NAME.CAR_DETAILS_CHECKOUT || routeName === ROUTE_NAME.BOOKING_CONFIRMATION;
    const { make = {} } = allFilters || {};
    const [scroll, setScroll] = useState(false);

    const { getLinkProps } = useLink();
    const { param, label } = GA_EVENT_DATA[routeName] || {};

    const withSearchBarRoutes = [ROUTE_NAME.HOME, ROUTE_NAME.LISTING];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [history]);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);
    const handleClickSearchBox = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        await yieldToMainThread();
        setIsSearchModal(!isSearchModal);
        if (!isSearchModal) {
            trackDesktopMultipleEventsAE("newHeaderSearchbarClick", param, { eventLabel: label });
        }
    };
    const toggelSearchBarModal = async () => {
        await yieldToMainThread();
        setIsSearchModal(!isSearchModal);
    };
    const popupRef = useOutsideClick(() => {
        if (isSearchModal) setIsSearchModal(false);
    });
    const onLogoClick = async () => {
        await yieldToMainThread();
        history.push("/");
        await yieldToMainThread();
        if (routeName === ROUTE_NAME.LISTING) {
            trackDesktopCustomEventsAE("c24LogoClick", { eventLabel: BOOKING_CONFIRMATION_SOURCE.LISTING });
        } else if (routeName === ROUTE_NAME.DETAIL_PAGE) {
            trackDesktopCustomEventsAE("c24LogoDetailClick", { eventLabel: "detail" });
        } else {
            trackDesktopCustomEventsAE("logoClick");
        }
        trackDesktopMultipleEventsAE("newHeaderCars24LogoClick", param, { eventLabel: label });
    };

    const onLocationClick = async () => {
        await yieldToMainThread();
        updateLocationPopupShownConnect(true);
        trackDesktopCustomEventsAE("locationPageShown", { eventLabel: "listing_change" });
        trackDesktopMultipleEventsAE("newHeaderLocationClick", param, { eventLabel: label });
    };

    const fireGaEvents = (eventAction, eventLabel) => {
        trackDesktopMultipleEventsAE("newHeaderSeoItemsClick", param, { eventAction, eventLabel });
    };

    const onSellItemClick = async (url) => {
        await yieldToMainThread();
        window.open(url, "_blank");
        fireGaEvents("sell_any_car_seo_click", url);
    };

    const handleEvents = (url) => {
        fireGaEvents("buy_user_car_seo_click", url);
    };

    return (
        <React.Fragment>
            <div styleName={`styles.headerRef ${!isHomePage ? "listingHeaderHeight" : ""}`} >
                <div styleName={`styles.mainHeader ${!isHomePage && !isCheckoutPage ? "headerSticky" : ""} ${(isProfilePage || (scroll && !isHomePage)) ? "" : ""}`}>                <div styleName={"styles.mainWrapper"}>
                    <div className={""} styleName={"styles.align_center styles.flexRow"}>
                        <div styleName="styles.logoLocationWrap">
                            <div styleName="styles.logoWrapper">
                                {
                                     <a {...getLinkProps(`/`, { onClickHandler: () => onLogoClick() })}>
                                        <Logo isHomePage={true} height={36} width={80} />
                                    </a>
                                }
                            </div>
                            {!isCheckoutPage && <span styleName={"styles.locationWrap"} onClick={onLocationClick}>{selectedCityName} <img src={IconLocationOrange} alt="" height={20} width={20} /></span>}
                        </div>
                        {withSearchBarRoutes.includes(routeName) && <div styleName={"styles.globalSearch"} onClick={handleClickSearchBox}>
                            <div styleName="styles.hederGlobalSearch">
                                <div styleName={"styles.isSearchWrap"} ref={popupRef}>
                                    <MakeModalSearchInfo
                                        toggelSearchBarModal={toggelSearchBarModal}
                                        source={routeName}
                                        setSearch={setSearchTerm}
                                        searchVal={searchTerm}
                                        param={param} label={label} isNew
                                        showSearchContent={isSearchModal}
                                        isNewHeader={true}
                                    />
                                </div>
                            </div>

                        </div>}
                        <div styleName={"styles.rightMenuGridWrap"}>
                            <MenuSideBar
                                locales={homeObject.userDropDown}
                                isHomePage={isHomePage}
                                routeName={routeName}
                                isLoggedIn={isLoggedIn}
                                showLogin={!isLoggedIn}
                                param={param}
                                label={label}
                                isNewHeader={true}
                                reloadCarListConnect={reloadCarListConnect}
                            />
                        </div>
                    </div>
                </div>
                </div>

            </div>
            {!isCheckoutPage && <div styleName={"styles.secondaryMenu"}>
                <div styleName={"styles.container"}>
                    <ul styleName={"styles.dFlex styles.align_center"}>
                        {!isCheckoutPage && <UsedCarDropdown make={make} isListingPage={true} handleEvents={handleEvents} cities={cities} />}
                        <li styleName={`styles.cityWrap ${isCheckoutPage ? "" : "styles.marginLeft"}`}>
                            <p styleName={"styles.cities"}>
                                <label>Sell car</label>
                                <span><ArrowIcon darkblue rotateBy={0} /></span>
                            </p>
                            <div styleName={"styles.dropdownMenu"}>
                                <ul>
                                    {SELL_CAR_HEADER_INFO.map((info, index) => (
                                        <li key={index}>
                                            <a
                                                {...getLinkProps(
                                                    `${info.link}`,
                                                    {
                                                        onClickHandler: () =>
                                                            onSellItemClick(
                                                                `${appUrl()}${info.link}`
                                                            )
                                                    }
                                                )}
                                            >{info.text}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </li>
                        <a styleName={`styles.carLoan`} href={`/${ROUTE_PATHS.carLoan}/`} onClick={() => {
                            trackDesktopCustomEventsAE("carLoanDetailPage", { eventLabel: label });
                        }}>Car loan calculator</a>
                    </ul>
                </div>
            </div>}
        </React.Fragment >
    );
};

HeaderRevampV2.propTypes = {
    history: PropTypes.object.isRequired,
    selectedCity: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string
    }),
    route: PropTypes.object,
    filters: PropTypes.object,
    urlOptions: PropTypes.object,
    selectedFilters: PropTypes.array,
    allFilters: PropTypes.object,
    updateCityConnect: PropTypes.func,
    resetSellerFaqsConnect: PropTypes.func,
    secureToken: PropTypes.string,
    mobilePhone: PropTypes.string,
    gaId: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    userName: PropTypes.string,
    appliedFilters: PropTypes.array,
    reloadCarListConnect: PropTypes.func,
    userProfileConnect: PropTypes.func,
    updateLocationPopupShownConnect: PropTypes.func,
    source: PropTypes.string,
    cities: PropTypes.array
};

export default withLogin(HeaderRevampV2);
