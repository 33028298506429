import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MenuSideBar from "./component";

// Actions
import { fetchCarDetails } from "../car-details/actions";

const mapStateToProps = ({
    user: {
        name,
        isLoggedIn,
        isFetched
    },
    myWishlist: {
        wishlistedCarsList
    },
    carDetails: {
        content: {
            appointmentId: detailPageCarId
        }
    }
}) => ({
    userName: name,
    isLoggedIn,
    isFetched,
    wishlistedCarsList,
    detailPageCarId

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCarDetailsConnect: fetchCarDetails
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuSideBar);
