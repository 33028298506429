import React from "react";
import close from "./images/cross.svg";
import PropTypes from "prop-types";

const CrossIconV2 = ({ onClick }) => {
    return (
        <img src={close} alt="cancel" onClick={onClick} />
    );
};

CrossIconV2.propTypes = {
    onClick: PropTypes.func
};

export default CrossIconV2;
