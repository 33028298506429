/* eslint-disable no-magic-numbers */
import parseCookie from "../../../utils/helpers/parse-cookie";
import saveCookie from "../../../utils/helpers/save-cookie";
import { COOKIE_SEARCH_KEYWORDS, NUMBER } from "../../../constants/app-constants";

export const updateSearchList = (selectedValue) => {
    const { value = "" } = selectedValue;
    if (parseCookie(COOKIE_SEARCH_KEYWORDS)) {
        let options = JSON.parse(parseCookie(COOKIE_SEARCH_KEYWORDS));
        options.forEach((item, index) => {
            if (item?.value === value) {
                options.splice(index, 1);
            }
        });
        if (options.length === NUMBER.SIX) options.pop(); //for fifo
        options = [selectedValue, ...options]; //treating it like queue instead of stack
        document.cookie = `${COOKIE_SEARCH_KEYWORDS}=${JSON.stringify(options)};path=/`;
    } else {
        document.cookie = `${COOKIE_SEARCH_KEYWORDS}=${JSON.stringify([selectedValue])};path=/`;
    }
};

export const getRecentSearches = () => {
    const defaultOptions = [];
    let data = {};
    if (parseCookie(COOKIE_SEARCH_KEYWORDS)) {
        data = { label: "Recent Searches" };
        const parsedOptions = JSON.parse(parseCookie(COOKIE_SEARCH_KEYWORDS));
        //doing this manipulation to get position for ga events else not required
        data.options = parsedOptions.map((item, position) => {
            return typeof item === "object" ? {
                ...item,
                label: item?.value,
                group: "Recent Searches",
                position: position + 1
            } : {
                value: item,
                label: item,
                type: "Make",
                group: "Recent Searches",
                position: position + 1
            };
        });
        defaultOptions.push(data);
    }
    return [{
        label: "Recent Searches",
        options: defaultOptions
    }];
};

export const removeRecentSearch = (value) => {
    const recentSearches = parseCookie(COOKIE_SEARCH_KEYWORDS);
    const parsedRecentSearches = recentSearches ? JSON.parse(recentSearches) : [ ];
    const updatedRecentSearches = parsedRecentSearches.filter(item => item?.value !== value);
    saveCookie(COOKIE_SEARCH_KEYWORDS, JSON.stringify(updatedRecentSearches), NUMBER.THREE_HUNDRED_SIXTY_FIVE);
};

