import { UsedCarService } from "../../../service";

const fetchCarSearch = (payload) => () => {
    return new Promise((resolve) => {
        UsedCarService.getCarsSearch(payload)
            .then(response => resolve(response.data.data));
    });
};

export {
    fetchCarSearch
};
