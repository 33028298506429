import { ROUTE_NAME } from "../../../constants/routes-constants";

export const SELL_CAR_HEADER_INFO = [{
    text: "Sell car",
    link: "/sell-used-car/"
}, {
    text: "Sell car Dubai",
    link: "/sell-used-car-dubai/"
}, {
    text: "Sell car Abu Dhabi",
    link: "/sell-used-car-abu-dhabi/"
}];

export const BUDGET_LIST = [{
    text: "Used Cars Under 25000 AED",
    link: "/buy-used-cars-under-25000-aed-dubai/"
}, {
    text: "Used Cars Under 40000 AED",
    link: "/buy-used-cars-under-40000-aed-dubai/"
}, {
    text: "Used Cars Under 60000 AED",
    link: "/buy-used-cars-between-55000-to-65000-aed-dubai/"
}, {
    text: "Used Cars Under 80000 AED",
    link: "/buy-used-cars-between-60000-to-100000-aed-dubai/"
}, {
    text: "Used Cars Above 100000 AED",
    link: "/buy-used-cars-above-100000-aed-dubai/"
}];

export const SEARCH_SUGGESTIONS = ["Search Toyota", "Search Nissan Sunny", "Search by Brand or Model"];

export const GA_EVENT_MAP = {
    [ROUTE_NAME.HOME]: "homePageSearch",
    [ROUTE_NAME.DETAIL_PAGE]: "searchBarDetailClick",
    [ROUTE_NAME.LISTING]: "listingSearchClick"
};

export const GA_EVENT_DATA = {
    [ROUTE_NAME.HOME]: { param: "Homepage", label: "home" },
    [ROUTE_NAME.LISTING]: { param: "Listing_Page", label: "listing" },
    [ROUTE_NAME.DETAIL_PAGE]: { param: "Detail_Page", label: "detail" }
};
