import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Modal from "../../shared/modal";
import CloseButton from "../../shared/icon-cross";

//Images
import ImageQr from "./images/iphone.svg";
import IconAppStore from "./images/app-store.png";
import IconPlayStore from "./images/play-store.png";

const DownloadAppPopup = ({onModalClose}) => {
    return (
        <Modal isOpen={true} close={onModalClose}>
            <div styleName={"styles.wrapper"}>
                <div styleName={"styles.headerWrapp"}>
                    <h3>Get the CARS24 App</h3>
                    <CloseButton type="blue" onClickHandler={onModalClose} />
                </div>
                <img styleName={"styles.qrImage"} src={ImageQr} alt="" width={147} height={156} />
                <p>Scan the QR code to download the app</p>
                <div styleName={"styles.flexRow"}>
                    <span>
                        <img src={IconPlayStore} alt="" width={108} height={30} />
                    </span>
                    <span>
                        <img src={IconAppStore} alt="" width={93} height={30} />
                    </span>
                </div>
            </div>
        </Modal>
    );
};

DownloadAppPopup.propTypes = {
    onModalClose: PropTypes.func
};

export default DownloadAppPopup;
