/* eslint-disable max-statements */
/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
/* eslint-disable no-unused-expressions */
import React, { useState, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Images
import MenuHamburgerWhite from "./images/menu-hamburger-white.svg";
import editIcon from "./images/edit-icon.svg";
import CallCircle from "./images/call-circle.svg";
import IconPerson from "./images/person.svg";
//import IconWishlist from "./images/wishlist-outline.svg";
import IconCarKey from "./images/car-key.svg";
import IconQr from "./images/qr-code.svg";

// Component
import Login from "./../login-revamp";
import withLogin from "../with-login";
//import WishlistNudge from "../wishlist-nudge";

// Constant
import { CALL_CENTER_NUMBER } from "../../../constants/app-constants";
import { ROUTE_NAME } from "../../../constants/routes-constants";
import { SOURCES } from "../login/constants";
import { trackMobileCustomEventsAE } from "../../../tracking";
import { SELLER_HOME_ROUTE } from "../../../constants/ae/sell-car-online";
import { trackDesktopCustomEventsAE, trackDesktopMultipleEventsAE } from "../../../tracking/desktop/tracking";
import DownloadAppPopup from "../download-app-popup";

const MenuDropDown = ({ isLoggedIn,
    isFetched,
    userName,
    locales,
    param, label: eventLabel, isNewHeader, wishlistedCarsLength,
    onClickLogin = () => { },
    onNavigate = () => { }
}) => {
    const MY_BOOKING_ROUTE = `/my-bookings`;
    const MY_WHISLIST_ROUTE = `/my-wishlist`;
    const trackFAQEvent = () => {
        if (isNewHeader) {
            trackDesktopMultipleEventsAE("newHeaderMenuItemsClick", param, { eventLabel, eventAction: "faq_listing" });
        } else trackDesktopCustomEventsAE("faqListing", { eventLabel: "listing_menu" });
    };
    const trackCallEvent = () => {
        if (isNewHeader) {
            trackDesktopMultipleEventsAE("newHeaderMenuItemsClick", param, { eventLabel, eventAction: "call_listing" });
        } else trackDesktopCustomEventsAE("callListing", { eventLabel: "listing_menu" });
    };

    return (<div styleName={"styles.dropdownMenuWrap styles.listingDropdownWrap"}>
        {(isLoggedIn && isFetched && !isNewHeader) && (
            <Fragment>
                <div styleName={"styles.userDetailWrap1"}>
                    <h4 styleName={"styles.userName"}>
                        {locales.hi}, {userName || "Customer"}
                    </h4>
                    <Link to={`/profile`}>
                        <img src={editIcon} alt="Edit" />
                    </Link>
                </div>
            </Fragment>
        )}
        <ul>
            {!isLoggedIn && (
                <li>
                    <a onClick={onClickLogin}>{locales.login}</a>
                </li>
            )}
            <li>
                <a
                    onClick={(e) => onNavigate(e, MY_BOOKING_ROUTE)}
                >
                    {locales.myBooking}
                </a>
            </li>
            <li styleName={"styles.wishlistWrap"}>
                <a
                    onClick={(e) => onNavigate(e, MY_WHISLIST_ROUTE)}
                >
                    {locales.myWishlist}
                </a>
                {wishlistedCarsLength > 0 && <p styleName={"styles.wishlistCount"}>{wishlistedCarsLength}</p>}
            </li>

            {
                <li onClick={trackFAQEvent}>
                    <Link to={`/faq/`}>{locales.faqText}</Link>
                </li>
            }
        </ul>
        {isLoggedIn && <div styleName={"styles.divider"} />}
        <div styleName={"styles.headerMenuCall"} onClick={trackCallEvent}>
            <a href={`tel:${CALL_CENTER_NUMBER.value}`}>
                <label>
                    <small>
                        10{locales.am} - 7{locales.pm} GST
                    </small>
                    <span>{CALL_CENTER_NUMBER.label}</span>
                </label>
                <div styleName={"styles.imageWrap"}>
                    <img src={CallCircle} alt="Sell Car: 8am - 10pm" width="14" height="14" />
                </div>
            </a>
        </div>
    </div>
    );
};

MenuDropDown.propTypes = {
    locales: PropTypes.object,
    userName: PropTypes.string,
    param: PropTypes.string,
    label: PropTypes.string,
    isNewHeader: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isFetched: PropTypes.bool,
    routeName: PropTypes.string,
    onClickLogin: PropTypes.func,
    onNavigate: PropTypes.func,
    isListingPage: PropTypes.bool,
    wishlistedCarsLength: PropTypes.number
};

const skipLoginRoute = [ROUTE_NAME.CAR_DETAILS_CHECKOUT, ROUTE_NAME.BOOKING_CONFIRMATION];

const MenuSideBar = ({
    isLoggedIn,
    isFetched,
    userName,
    showLogin,
    setShowLogin,
    locales,
    isHomePage,
    routeName,
    param,
    label,
    isNewHeader,
    wishlistedCarsList,
    reloadCarListConnect,
    detailPageCarId,
    fetchCarDetailsConnect
}) => {

    const history = useHistory();
    const MY_BOOKING_ROUTE = `/my-bookings`;
    const MY_WHISLIST_ROUTE = `/my-wishlist`;
    const [source, setSource] = useState("login");
    const [showGetAppModal, setShowGetAppModal] = useState(false);

    const onClickLogin = (e) => {
        e.stopPropagation();
        e.preventDefault();
        trackDesktopMultipleEventsAE("newHeaderLoginClick", param, { eventLabel: label });
        if (skipLoginRoute.includes(routeName)) {
            return;
        } else {
            setSource("login");
            setShowLogin(true);
        }
    };

    const onNavigate = (e, route = "") => {
        e.stopPropagation();
        setSource(route === MY_WHISLIST_ROUTE ? "wishlist" : "booking");
        if (route === MY_WHISLIST_ROUTE) {
            trackDesktopMultipleEventsAE("newHeaderWishlistClick", param, { eventLabel: label });
        }
        if (route === MY_BOOKING_ROUTE) {
            trackDesktopMultipleEventsAE("newHeaderMenuItemsClick", param, { eventLabel: label, eventAction: "my_bookings" });
        }
        if (route && isLoggedIn) {
            history.push(route);
        } else {
            setShowLogin(true);
        }
    };

    const handleLoginSuccess = () => {
        source === "wishlist" && history.push(MY_WHISLIST_ROUTE);
        source === "booking" && history.push(MY_BOOKING_ROUTE);
        source === "login" && setShowLogin(false);
        reloadCarListConnect(true);
        if (detailPageCarId) {
            fetchCarDetailsConnect(detailPageCarId, true, "updatedGalleryDesign");
        }
    };
    const handleViewProfile = () => {
        history.push("/profile");
    };

    const handleSellCarClick = () => {
        trackMobileCustomEventsAE("buySellMyCar", { eventLabel: "sell_top_bar" });
        history.push(SELLER_HOME_ROUTE);
    };

    const trackMenuClick = () => {
        if (isNewHeader) {
            trackDesktopMultipleEventsAE("newHeaderMenuClick", param, { eventLabel: label });
        } else trackDesktopCustomEventsAE("listingMenu", { eventLabel: "listing" });
    };
    const handleGetAppClick = () => {
        trackDesktopCustomEventsAE("listingPageAppDownloadBannerClick");
        setShowGetAppModal(true);
    };
    return (
        <Login
            isVisible={showLogin}
            onClose={() => setShowLogin(false)}
            onSuccess={handleLoginSuccess}
            loginFor={source === "wishlist" ? SOURCES.WISHLIST : (source === "booking" ? SOURCES.MY_BOOKINGS : SOURCES.MENU)}
        >
            <div
                styleName={
                    "styles.dropdownWrapper styles.menuIcon styles.headerMenuDropdown"
                }
            >
                <div styleName={"styles.userHamburgerListing"}>
                    <ul>
                        {isHomePage && <li styleName={"styles.sellCarWrap"} onClick={handleSellCarClick}><a>Sell Car <img src={IconCarKey} alt="" width={20} height={20} /></a></li>}
                        {!isHomePage && <li styleName={"styles.getAppWrap"} onClick={handleGetAppClick}><a>Get App<img src={IconQr} alt="" width={20} height={20} /></a></li>}
                        {/* {(routeName !== ROUTE_NAME.CAR_DETAILS_CHECKOUT && routeName !== ROUTE_NAME.BOOKING_CONFIRMATION && routeName !== ROUTE_NAME.DETAIL_PAGE) && (
                                <li>
                                    <a
                                        onClick={(e) => onNavigate(e, MY_WHISLIST_ROUTE)}
                                        gaevent="wishlistClicked"
                                    >
                                        {locales.myWishlist}
                                        <img src={IconWishlist} alt="Wishlist Icon" height={20} width={20} />
                                    </a>
                                    {wishlistedCarsList?.length > 0 && <p styleName={"styles.dotWrap"} />}
                                    <WishlistNudge />
                                </li>
                            )} */}
                        {!isLoggedIn ? (
                            <li styleName="styles.loginUser">
                                <a onClick={onClickLogin}>
                                    {locales.login}
                                    <img src={IconPerson} alt="User Icon" height={20} width={20} />
                                </a>
                            </li>
                        ) : <li styleName="styles.loginUser" onClick={handleViewProfile}>
                            <span styleName={"styles.userName"}>
                                Hi, {userName}
                                <img src={IconPerson} alt="User Icon" height={20} width={20} />
                            </span>
                        </li>}
                    </ul>
                    <div styleName={"styles.hamburgerIcon"}>
                        <img src={MenuHamburgerWhite} alt="CARS24 header menu" height={24} width={24} onClick={trackMenuClick} />
                        <MenuDropDown
                            isLoggedIn={isLoggedIn}
                            isFetched={isFetched}
                            userName={userName}
                            isListingPage={routeName === ROUTE_NAME.LISTING}
                            locales={locales}
                            routeName={routeName}
                            onClickLogin={onClickLogin}
                            onNavigate={onNavigate}
                            param={param}
                            label={label}
                            isNewHeader={isNewHeader}
                            wishlistedCarsLength={wishlistedCarsList?.length}
                        />
                    </div>
                </div>
            </div>
            {showGetAppModal && <DownloadAppPopup onModalClose={() => setShowGetAppModal(false)} />}
        </Login>
    );
};

MenuSideBar.propTypes = {
    locales: PropTypes.object,
    setShowLogin: PropTypes.func,
    showLogin: PropTypes.bool,
    userName: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    isFetched: PropTypes.bool,
    isHomePage: PropTypes.bool,
    routeName: PropTypes.string,
    param: PropTypes.string,
    label: PropTypes.string,
    isNewHeader: PropTypes.bool,
    wishlistedCarsList: PropTypes.array,
    reloadCarListConnect: PropTypes.func,
    detailPageCarId: PropTypes.string,
    fetchCarDetailsConnect: PropTypes.func
};

export default withLogin(MenuSideBar);
