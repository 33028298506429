import React from "react";
import styles from "./skeleton.css";

const Skeleton = () => {
    return (
        <div styleName={"styles.skeletonWrap"}>
           <h2 className="shimmer" styleName={"styles.heading"} />
           <div styleName={"styles.skeletonCircleWrap"}>
               <div className="shimmer"  />
               <div className="shimmer"  />
               <div className="shimmer"  />
               <div className="shimmer"  />
               <div className="shimmer"  />
           </div>
           <div styleName={"styles.dFlex styles.paraHeading"}>
                <div className="shimmer"  />
                <div className="shimmer"  />
                <div className="shimmer"  />
                <div className="shimmer"  />
                <div className="shimmer"  />
           </div>
           <h2 className="shimmer" styleName={"styles.heading"} />
           <div styleName={"styles.dFlex styles.rectangleGrid"}>
                <div className="shimmer"  />
                <div className="shimmer"  />
                <div className="shimmer"  />
           </div>

           <h2 className="shimmer" styleName={"styles.heading"} />
           <div styleName={"styles.dFlex  styles.popularGrid"}>
                <div className="shimmer"  />
                <div className="shimmer"  />
                <div className="shimmer"  />
           </div>
           <div styleName={"styles.dFlex styles.popularGridparaHeading"}>
                <div className="shimmer"  />
                <div className="shimmer"  />
                <div className="shimmer"  />
           </div>
           <div styleName={"styles.dFlex  styles.popularGrid styles.mtEighteen"}>
                <div className="shimmer"  />
                <div className="shimmer"  />
                <div className="shimmer"  />
           </div>
           <div styleName={"styles.dFlex styles.popularGridparaHeading"}>
                <div className="shimmer"  />
                <div className="shimmer"  />
                <div className="shimmer"  />
           </div>
           <h2 className="shimmer" styleName={"styles.heading"} />
           <div styleName={"styles.skeletonCircleWrap"}>
               <div className="shimmer"  />
               <div className="shimmer"  />
               <div className="shimmer"  />
               <div className="shimmer"  />
               <div className="shimmer"  />
           </div>
           <div styleName={"styles.dFlex styles.paraHeading"}>
                <div className="shimmer"  />
                <div className="shimmer"  />
                <div className="shimmer"  />
                <div className="shimmer"  />
                <div className="shimmer"  />
           </div>
           <h2 className="shimmer" styleName={"styles.heading"} />
           <div styleName={"styles.dFlex styles.rectangleGrid"}>
                <div className="shimmer"  />
                <div className="shimmer"  />
                <div className="shimmer"  />
           </div>
        </div>
    );
};
export default Skeleton;
