import { AE_CITY } from "../../../constants/app-constants";
import { findByKey } from "../../../utils/helpers/find-by-key";
import saveCookie from "../../../utils/helpers/save-cookie";
import Types from "./types";

const updateCity = (city, lat = null, lng = null) => (dispatch, store) => {
    const cityList = store().config.cityList;
    const selectedCity = findByKey(cityList, "code", city);
    saveCookie(AE_CITY, selectedCity.code);
    return dispatch({
        type: Types.UPDATE_CITY,
        city,
        cityList,
        lat,
        lng
    });
};

export {
    updateCity
};
