import React from "react";
import styles from "./styles.css";
import dashedLowercase from "./../../../utils/helpers/dashed-lowercase";
import { DEFAULT_AE_CITY_NAME, GLOBAL_URL, NUMBER } from "./../../../constants/app-constants";
import PropTypes from "prop-types";

//Images
import ArrowIcon from "./../../shared/arrow";
import IconForward from "../../shared/images/icons/forward-arrow.svg";
// import IconForwardBlue from "../../shared/images/icons/arrow-down-blue.svg";
import useLink from "../../../hooks/use-link";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import { DEFAULT_MAKE_MODELS } from "../../../utils/filters-v2/constants";
import { capitalizeFirstLetter } from "../../../utils/helpers/title-to-upperCase";

const UsedCarDropdown = ({ make, isListingPage, handleEvents, cities }) => {
    const BASE_URL = "/buy-used-";
    const { getLinkProps } = useLink();
    const onClickUrl = (url) => {
        trackDesktopCustomEventsAE("buyUserCarSeoClick", { eventLabel: url });
        if (handleEvents) {
            handleEvents(url);
        }
        window.open(url, "_blank");
    };

    return (
        <React.Fragment>
            <li styleName={"styles.buyUsedCar"} className={"buyUsedCar"}>
                <a>
                    Buy Used Car{" "}
                    {isListingPage ? (
                        <ArrowIcon darkblue rotateBy={0} />
                    ) : (
                        <ArrowIcon rotateBy={90} />
                    )}
                </a>
                <div
                    styleName={"styles.buyUsedCarWrap"}
                    className="buyUsedCarWrapHeader"
                >
                    <div>
                        <p styleName={"styles.catHeading"}>Used car for sale by make</p>
                        <ul styleName={"styles.menuItemWrap"}>
                            {make?.options?.slice(0, NUMBER.TEN).map((makeOption) => (
                                <li
                                    key={makeOption.displayText}
                                    styleName={"styles.dropdownMenu"}
                                >
                                    <a
                                        {...getLinkProps(
                                            `${BASE_URL}${dashedLowercase(
                                                makeOption.displayText
                                            )}-cars-${dashedLowercase(DEFAULT_AE_CITY_NAME)}/`,
                                            {
                                                onClickHandler: () =>
                                                    onClickUrl(
                                                        `${BASE_URL}${dashedLowercase(
                                                            makeOption.displayText
                                                        )}-cars-${dashedLowercase(DEFAULT_AE_CITY_NAME)}/`
                                                    )
                                            }
                                        )}
                                    >
                                        Used {makeOption.displayText?.length === NUMBER.THREE ? makeOption?.displayText?.toUpperCase() : capitalizeFirstLetter(makeOption?.displayText)} cars in {DEFAULT_AE_CITY_NAME}
                                        <div styleName={"styles.forwardIconWrap"}>
                                            <img src={IconForward} alt="" width="12" height="12" />
                                        </div>
                                    </a>
                                </li>
                            ))}
                            <li styleName={"styles.viewAllCta"}>
                                <a
                                    {...getLinkProps(
                                        `${BASE_URL}cars-${dashedLowercase(
                                            DEFAULT_AE_CITY_NAME
                                        )}/`,
                                        {
                                            onClickHandler: () =>
                                                onClickUrl(
                                                    `${BASE_URL}cars-${dashedLowercase(
                                                        DEFAULT_AE_CITY_NAME
                                                    )}/`
                                                )
                                        }
                                    )}
                                >
                                    View All Makes
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p styleName={"styles.catHeading"}>Used car for sale by model</p>
                        <ul styleName={"styles.menuItemWrap"}>
                            {(DEFAULT_MAKE_MODELS || []).map((makeModel, index) => (
                                <li
                                    key={index}
                                    styleName={"styles.dropdownMenu"}
                                >
                                    <a
                                        {...getLinkProps(
                                            `${BASE_URL}${dashedLowercase(makeModel.make)}-${dashedLowercase(makeModel.model)}-cars-${dashedLowercase(DEFAULT_AE_CITY_NAME)}/`,
                                            {
                                                onClickHandler: () =>
                                                    onClickUrl(
                                                        `${BASE_URL}${dashedLowercase(makeModel.make)}-${dashedLowercase(makeModel.model)}-cars-${dashedLowercase(DEFAULT_AE_CITY_NAME)}/`
                                                    )
                                            }
                                        )}
                                    >
                                        Used {makeModel.make} {makeModel.model} cars in  {DEFAULT_AE_CITY_NAME}
                                        <div styleName={"styles.forwardIconWrap"}>
                                            <img src={IconForward} alt="" width="12" height="12" />
                                        </div>
                                    </a>
                                </li>
                            ))}
                            <li styleName={"styles.viewAllCta"}>
                                <a
                                    {...getLinkProps(
                                        `${BASE_URL}cars-${dashedLowercase(
                                            DEFAULT_AE_CITY_NAME
                                        )}/`,
                                        {
                                            onClickHandler: () =>
                                                onClickUrl(
                                                    `${BASE_URL}cars-${dashedLowercase(
                                                        DEFAULT_AE_CITY_NAME
                                                    )}/`
                                                )
                                        }
                                    )}
                                >
                                    View All Models
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p styleName={"styles.catHeading"}>Used car for sale by location</p>
                        <ul styleName={"styles.menuItemWrap"}>
                            <li
                                key={"uae"}
                                styleName={"styles.dropdownMenu"}
                            >
                                <a
                                    {...getLinkProps(
                                        `${GLOBAL_URL}`,
                                        {
                                            onClickHandler: () =>
                                                onClickUrl(
                                                    `${GLOBAL_URL}`
                                                )
                                        }
                                    )}
                                >
                                    Used cars in UAE
                                    <div styleName={"styles.forwardIconWrap"}>
                                        <img src={IconForward} alt="" width="12" height="12" />
                                    </div>
                                </a>
                            </li>
                            {(cities || []).map((city, cityIndex) => (
                                <li
                                    key={cityIndex}
                                    styleName={"styles.dropdownMenu"}
                                >
                                    <a
                                        {...getLinkProps(
                                            `${BASE_URL}cars-${dashedLowercase(city?.name)}/`,
                                            {
                                                onClickHandler: () =>
                                                    onClickUrl(
                                                        `${BASE_URL}cars-${dashedLowercase(city?.name)}/`
                                                    )
                                            }
                                        )}
                                    >
                                        Used cars in {city?.name}
                                        <div styleName={"styles.forwardIconWrap"}>
                                            <img src={IconForward} alt="" width="12" height="12" />
                                        </div>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </li>
        </React.Fragment>
    );
};

UsedCarDropdown.propTypes = {
    make: PropTypes.object,
    isListingPage: PropTypes.bool,
    handleEvents: PropTypes.func,
    cities: PropTypes.array
};

export default UsedCarDropdown;
