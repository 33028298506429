import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

// Component
import MakeModalSearchInfo from "./component";

// Actions
import { fetchCarSearch } from "./actions";

import { reloadCarList } from "../car-listing/actions";
import { clearAllFilters, fetchAutoSuggestions, setSearchResultTrackingData } from "../filters/actions";

const mapStateToProps = ({
    filters: {
        allFilters,
        selectedFilters,
        urlOptions
    },
    location: {
        selectedCity: {
            code: selectedCityCode,
            name: selectedCityName
        } = {}
    },
    user: { mobile, isLoggedIn }
}) => ({
    allFilters,
    selectedCityName,
    selectedCityCode,
    mobile,
    isLoggedIn,
    selectedFilters,
    urlOptions
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCarSearchConnect: fetchCarSearch,
    fetchAutoSuggestionsConnect: fetchAutoSuggestions,
    reloadCarListConnect: reloadCarList,
    clearAllFiltersConnect: clearAllFilters,
    setSearchResultTrackingDataConnect: setSearchResultTrackingData
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MakeModalSearchInfo));
