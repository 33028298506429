import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    UPDATE_CITY
`,
    {
        prefix: "header/"
    }
);
